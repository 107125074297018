<template>
  <div>
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="controller">
          <el-button type="text"><i class="icon el-icon-s-grid"></i></el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <div style="width: 100%;">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4" v-for="(item, key) in data.children" :key="key">
              <el-card :body-style="{ padding: '0px' }">
                <el-image :src="getImage(item.path)" class="image" :fit="'contain'" @click="navigate(item.name)"></el-image>
                <div class="info" style="" @click="navigate(item)">
                  <el-rate v-model="item.rate" disabled show-score text-color="#ff9900"></el-rate>
                  <span class="name">{{item.name}}</span>
                  <div class="price">{{item.price}}</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { /*mapGetters,*/ mapMutations} from "vuex";
export default {
  name: 'Category',
  data(){return{
    data: '',
    value2: 2,
    brandList:[
      {
        key: 'nokia',
        children: [
          {id: 1, name: 'Nokia A', path: '1.png', price: '$125', rate: 1, brand: 'Nokia'},
          {id: 2, name: 'Nokia B', path: '2.png', price: '$225', rate: 5, brand: 'Nokia'},
          {id: 3, name: 'Nokia C', path: '3.png', price: '$325', rate: 3, brand: 'Nokia'},
          {id: 4, name: 'Nokia D', path: '4.png', price: '$125', rate: 2, brand: 'Nokia'},
          {id: 5, name: 'Nokia E', path: '1.png', price: '$225', rate: 1, brand: 'Nokia'},
          {id: 6, name: 'Nokia F', path: '2.png', price: '$325', rate: 4, brand: 'Nokia'},
          {id: 7, name: 'Nokia G', path: '3.png', price: '$325', rate: 2, brand: 'Nokia'},
        ]
      },
      {
        key: 'zte',
        children: [
          {id: 1, name: 'ZTE A', path: '1.png', price: '$125', rate: 1, brand: 'ZTE'},
          {id: 2, name: 'ZTE B', path: '2.png', price: '$225', rate: 5, brand: 'ZTE'},
          {id: 3, name: 'ZTE C', path: '3.png', price: '$325', rate: 3, brand: 'ZTE'},
          {id: 4, name: 'ZTE D', path: '4.png', price: '$125', rate: 2, brand: 'ZTE'},
          {id: 5, name: 'ZTE E', path: '1.png', price: '$225', rate: 1, brand: 'ZTE'},
          {id: 6, name: 'ZTE F', path: '2.png', price: '$325', rate: 4, brand: 'ZTE'},
          {id: 7, name: 'ZTE G', path: '3.png', price: '$325', rate: 2, brand: 'ZTE'},
        ]
      },
      {
        key: 'samsung',
        children: [
          {id: 1, name: 'SAMSUNG A', path: '1.png', price: '$125', rate: 1, brand: 'SAMSUNG'},
          {id: 2, name: 'SAMSUNG B', path: '2.png', price: '$225', rate: 5, brand: 'SAMSUNG'},
          {id: 3, name: 'SAMSUNG C', path: '3.png', price: '$325', rate: 3, brand: 'SAMSUNG'},
          {id: 4, name: 'SAMSUNG D', path: '4.png', price: '$125', rate: 2, brand: 'SAMSUNG'},
          {id: 5, name: 'SAMSUNG E', path: '1.png', price: '$225', rate: 1, brand: 'SAMSUNG'},
          {id: 6, name: 'SAMSUNG F', path: '2.png', price: '$325', rate: 4, brand: 'SAMSUNG'},
          {id: 7, name: 'SAMSUNG G', path: '3.png', price: '$325', rate: 2, brand: 'SAMSUNG'},
        ]
      },
      {
        key: 'xiaomi',
        children: [
          {id: 1, name: 'XIAOMI A', path: '1.png', price: '$125', rate: 1, brand: 'XIAOMI'},
          {id: 2, name: 'XIAOMI B', path: '2.png', price: '$225', rate: 5, brand: 'XIAOMI'},
          {id: 3, name: 'XIAOMI C', path: '3.png', price: '$325', rate: 3, brand: 'XIAOMI'},
          {id: 4, name: 'XIAOMI D', path: '4.png', price: '$125', rate: 2, brand: 'XIAOMI'},
          {id: 5, name: 'XIAOMI E', path: '1.png', price: '$225', rate: 1, brand: 'XIAOMI'},
          {id: 6, name: 'XIAOMI F', path: '2.png', price: '$325', rate: 4, brand: 'XIAOMI'},
          {id: 7, name: 'XIAOMI G', path: '3.png', price: '$325', rate: 2, brand: 'XIAOMI'},
        ]
      },
      {
        key: 'apple',
        children: [
          {id: 1, name: 'APPLE A', path: '1.png', price: '$125', rate: 1, brand: 'Apple'},
          {id: 2, name: 'APPLE B', path: '2.png', price: '$225', rate: 5, brand: 'Apple'},
          {id: 3, name: 'APPLE C', path: '3.png', price: '$325', rate: 3, brand: 'Apple'},
          {id: 4, name: 'APPLE D', path: '4.png', price: '$125', rate: 2, brand: 'Apple'},
          {id: 5, name: 'APPLE E', path: '1.png', price: '$225', rate: 1, brand: 'Apple'},
          {id: 6, name: 'APPLE F', path: '2.png', price: '$325', rate: 4, brand: 'Apple'},
          {id: 7, name: 'APPLE G', path: '3.png', price: '$325', rate: 2, brand: 'Apple'},
        ]
      },
      {
        key: 'realmi',
        children: [
          {id: 1, name: 'Realmi A', path: '1.png', price: '$125', rate: 1, brand: 'Realmi'},
          {id: 2, name: 'Realmi B', path: '2.png', price: '$225', rate: 5, brand: 'Realmi'},
          {id: 3, name: 'Realmi C', path: '3.png', price: '$325', rate: 3, brand: 'Realmi'},
          {id: 4, name: 'Realmi D', path: '4.png', price: '$125', rate: 2, brand: 'Realmi'},
          {id: 5, name: 'Realmi E', path: '1.png', price: '$225', rate: 1, brand: 'Realmi'},
          {id: 6, name: 'Realmi F', path: '2.png', price: '$325', rate: 4, brand: 'Realmi'},
          {id: 7, name: 'Realmi G', path: '3.png', price: '$325', rate: 2, brand: 'Realmi'},
        ]
      },
    ]
  }},
  mounted(){
    this.getData(this.$route.params.name)
  },
  methods:{
    ...mapMutations([
      "SetDetail",
    ]),
    getData(value){
      this.data = this.brandList.find(o => o.key === value);
    },
    getImage(value){
      return require(`../assets/phone/${value}`)
    },
    navigate(value){
      this.SetDetail(value)
      this.$router.push({ path: `/product/${value.name}`, params: { name: value } })
    }
  }
}
</script>
<style scoped>
.controller{
  padding: 8px 40px;
  margin-bottom: 28px;
  background-color: #f6f6f6;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  text-align: left;
  border-radius: 5px;
  
}
.item{
}
>>>.el-card{
  background-color: #f6f6f6 !important;
  box-shadow: none !important;
  border-color: #ebebeb;
  border-radius: 0px; 
  margin-bottom: 20px;
  cursor: pointer;
}
>>>.el-card:hover{
  border-color: #ed1d24;
}
.info{
  padding: 14px; 
  background: white; 
  padding: 25px 30px;
  text-align: left;
}
.name{
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: #1b1b1b;
  margin: 5px 0px;
}
.name:hover{
  color: #ed1d24;
}
.price{
  font-weight: 600;
  font-size: 18px;
  color: #ed1d24;
}
.el-button--text{
  color: #ed1d24;
}
.icon{
  font-size: 22px;
}

.el-image{
  padding: 40px;
}
</style>